<div class="dates-box mb-3 {{ customClass }}" *ngIf="!end && showComponent && start">
    <span class="dates-row">
        <h6>
            {{ 'days-short.' + start.dayOfWeek | translate }} {{ start.day }}
            {{ 'months.' + start.month | translate }} {{ start.year }} - {{ start.hour }}:{{
                start.minute
            }}
        </h6>
    </span>
    <div class="place-row" *ngIf="locationName">
        <h4>{{ locationName }}</h4>
    </div>
</div>

<!-- if has end date -->
<div class="dates-box mb-3 {{ customClass }}" *ngIf="end && showComponent && start">
    <span class="dates-row">
        <p>{{ 'date.da' | translate }}&nbsp;</p>
        <h6>
            {{ 'days-short.' + start.dayOfWeek | translate }} {{ start.day }}
          {{ 'months.' + end.month | translate }} {{ end.year }}
        </h6>
    </span>
    <div class="place-row" *ngIf="locationName">
        <h4>{{ locationName }}</h4>
    </div>
</div>
